

import { Component } from "vue-property-decorator";
import { Denial } from "@/models/tasks.model";
import AddComment from "@/views/AddComment.vue";
import Axios from "axios";
import { BASE_API_URL,EventBus } from "../../../../config";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddComment } })
export default class DenialComponent extends mixins(PipeLineSocketMixin, CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  private pipeLineHeaderData: any = {};
  private denialData = new Denial();
  public loanTxnId = null;
  public taskNameForRoom = 'denial';
  
  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Denial", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/Denial.mp4' });
  }
  public async submit(ifSave) {
    try {
      /**
       * Validating conditions here
       */
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment['validateComment'](ifSave);
      /**
       * If clicked on Complete then validate all fields otherwise
       * check conditions if not validate either both then return;
       */
      if ((ifSave ? false : !await this.$validator.validateAll() ) || !validComment)
        return;

      /**
       * Taking Formdata for sending files and data
       */
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      //  Appending LoanTxnId in formData
      formData.append("loanTxnId", this.loanTxnId);
      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.denialData.commentData.comment,
        showCommentToBroker: this.denialData.commentData.showCommentToBroker
      }
      if (this.denialData.commentData.commentAttachment.length > 0 && !this.denialData.commentData.commentAttachment[0].hasOwnProperty("path"))
        this.denialData.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.denialData.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.denialData.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));
      /**
       * Merging the save or complete option here
       */
      formData.append("options", JSON.stringify(this.denialData.options));
      formData.append("ifSave", ifSave);

      if (
        this.denialData.denialLetter &&
        !this.denialData.denialLetter.hasOwnProperty("path")
      ){
        this.denialData.denialLetter.forEach(file => {
          formData.append("denialLetter", file);
        });
      }

      /**
       * Finally sending request to server with formdata in body
       */
      let response = await Axios.post( BASE_API_URL + "wemloprocessor/denial", formData);
      /**
       * Printing response message here and redirecting to dashboard
       */
      this.$snotify[response.data.status == 200 ? "success" : response.data.status == 403 ? "info" : "error"](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }

      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.denialData.commentData.commentAttachment.length > 0 &&
      !this.denialData.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.denialData.commentData.commentAttachment = this.denialData.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
  }

  public displayUploadFiles(file, viewFileName) {
    //  this.fileNames =viewFileName
    this.$refs.comment["displayCommentAttachment"](file, true, viewFileName);
  }

  async uploadFile(event, uploadedFileName) {
    if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
          this.denialData[uploadedFileName].push(element);
      });
    }
  }



  public async fetchDenialTaskDetail() {
    try {
     this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchDenialTaskDetail",
        { loanTxnId: this.loanTxnId });
      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.denialData = response.data.denial;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }


  async mounted() {
    this.loanTxnId = this.$route.query.id;
    await this.fetchDenialTaskDetail();
  }


}
